import { FC, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
//@ts-ignore
import { showToast } from '@nodus/utilities-front'

import {
  BackButton,
  Container,
  FormControl,
  FormWrapper,
  Input,
  InputSize,
  Label,
  Loader,
  MultiTagsCard,
  Paragraph,
  ToggleSwitch
} from '../components'
import { ATTACK_PROTECTION_PATH } from '../constants'
import { useFetch } from '../hooks'
import { SEO, inputClasses } from '../utils'
import { BlockedIpsList, HeadlineStatus } from './components'

interface IBruteForceProtection {
  bruteForceProtection: boolean
  loginTresholdForAccountLockout: any
  blockBruteForceLogins: boolean
  accountLockout: boolean
  sendEmailNotificationsForBruteForce: boolean
  ipAddressAllowedDtos: string[]
}

export const BruteForceProtection: FC = () => {
  const navigate = useNavigate()
  const [ipAllowList, setApiAllowList] = useState<string[]>()

  const { handleSubmit, control, register, reset, watch } = useForm()

  const { apiCall: getBruteFroceSettings, loading, response } = useFetch('get')
  const { apiCall: editBruteForceProtectionSettings, loading: editLoading } =
    useFetch('post')

  useEffect(() => {
    SEO({
      title: 'Porta - Brute force protection'
    })

    getBruteFroceSettings(`Users/BruteForceProtectionSettings`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setApiAllowList(response?.ipAddressesAllowed)
    reset(response)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response])

  const onEditBruteForceProtectionSettings: SubmitHandler<any> = (
    data: IBruteForceProtection
  ) => {
    const bruteForceProtectionObj = {
      ...data,
      ipAddressesAllowed: ipAllowList,
      loginTresholdForAccountLockout: parseInt(
        data.loginTresholdForAccountLockout
      )
    }

    editBruteForceProtectionSettings(
      `Users/EditBruteForceProtectionSettings`,
      bruteForceProtectionObj,
      () => {
        showToast('success', 'Brute force protection saved successfully!')
        navigate(ATTACK_PROTECTION_PATH)
      },
      (err) => {
        debugger
      }
    )
  }

  const watchBruteForce = watch('bruteForceProtection')

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Container>
            <BackButton
              to={ATTACK_PROTECTION_PATH}
              className="mb-4"
              label="Back to Attack Protection"
            >
              <></>
            </BackButton>
            <HeadlineStatus
              control={control}
              title="Brute force protection"
              description="Safeguard against brute-force attacks that target a single user account. By default, this feature limits login attempts separately for each source IP address to limit the potential for attackers to lock legitimate users out of their account. Account Lockout mode can be enabled to limit attempts regardless of IP address."
              toggleId="brute-force-protection"
              toggleName="bruteForceProtection"
              statusEnabled={watchBruteForce}
            />
          </Container>

          <FormWrapper
            onSubmit={handleSubmit(onEditBruteForceProtectionSettings)}
            className="mt-6"
            loading={editLoading}
          >
            <h6 className="text-primary-mainText">Detection</h6>
            <div className="my-6">
              <h6 className="text-sm text-primary-mainText font-medium">
                Login Threshold
              </h6>
              <Paragraph>
                Manage thresholds for blocking suspicious traffic during end
                user authentication
              </Paragraph>
            </div>

            <FormControl description="Number of consecutive failed login attempts from a single user before blocking is triggered">
              <Label required text="Maximum Attempts" htmlFor="max-attempts" />
              <Input
                id="max-attempts"
                {...register('loginTresholdForAccountLockout')}
                inputSize={InputSize.sm}
                className={inputClasses}
                type="text"
              />
            </FormControl>

            <FormControl description="The IP AllowList gives you the ability to create a list of trusted IP addresses from which your users can access your resources. These IP addresses will not have attack protection enforced against them. This field allows you to specify multiple IP addresses, or ranges, by comma-separating them. You can use IPv4 or IPv6 addresses and CIDR notation">
              <Label text="IP AllowList" />
              <MultiTagsCard
                hasInput
                noSuggestedTags
                tags={ipAllowList || []}
                setTags={setApiAllowList}
              />
            </FormControl>

            <FormControl>
              <BlockedIpsList endpoint="Users/BlockedIpAddressesForBruteForce" />
            </FormControl>

            <h6 className="text-primary-mainText mb-6">Response</h6>
            <h6 className="text-sm text-primary-mainText mb-6">
              Block Settings
            </h6>
            <FormControl>
              <Controller
                control={control}
                name="blockBruteForceLogins"
                render={({ field: { onChange, value, ref } }: any) => (
                  <ToggleSwitch
                    id="block-brute-force-logins"
                    onChange={onChange}
                    checked={value || false}
                    inputRef={ref}
                    label="Block Brute-force Logins"
                    description="Once enforced, Porta will block login attempts for a flagged user account."
                  />
                )}
              />
            </FormControl>

            <FormControl>
              <Controller
                control={control}
                name="accountLockout"
                render={({ field: { onChange, value, ref } }: any) => (
                  <ToggleSwitch
                    id="account-lockout"
                    onChange={onChange}
                    checked={value || false}
                    inputRef={ref}
                    label="Account Lockout"
                    description="Lockout an account regardless of IP Address when we detect numerous unsuccessful login attempts."
                  />
                )}
              />
            </FormControl>
          </FormWrapper>
        </>
      )}
    </div>
  )
}
