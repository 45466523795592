//@ts-ignore
import { GjIconReact as Icon } from '@nodus/utilities-front'
import { Button, BtnType } from '../../components/button'
import { copyToClipboard, cx } from '../../utils'

interface ICopyCodeButton {
  copyText: string | any
  successMessage: string
  className?: string
}

export const CopyCodeButton: React.FC<ICopyCodeButton> = (props) => {
  return (
    <Button
      btnType={BtnType.tertiary}
      title="Copy"
      type="button"
      className={cx([
        'text-primary-mainText hover:text-primary',
        props.className
      ])}
      onClick={() => copyToClipboard(props.copyText, props.successMessage)}
    >
      <div className="flex items-center justify-center w-5 h-5">
        <Icon name="Copy" size={20} className="mx-auto" />
      </div>
    </Button>
  )
}
