import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import singleSpaReact from 'single-spa-react'
import Root from './root.component'
import './styles/index.scss'

function rootComponent() {
  return (
    <div
      id="nodus-app__porta-settings"
      className="porta-app__container nodus-app__porta-settings"
    >
      <BrowserRouter>
        <Root />
      </BrowserRouter>
    </div>
  )
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent,
  domElementGetter: () => document.getElementById('nodus-app')!
})

export const { bootstrap, mount, unmount } = lifecycles
