import { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NavLink } from 'react-router-dom'
//@ts-ignore
import { showToast } from '@nodus/utilities-front'
import {
  Card,
  CardSpace,
  CardStyle,
  Paragraph,
  ParagraphSize,
  ToggleSwitch
} from '../components'
import { MainContainer } from '../components/layout/MainContainer'

import { SMART_EMAILING_PATH } from '../constants'
import { useFetch } from '../hooks'
import { SEO } from '../utils'
import { Status } from '../components'

interface IEmailProvider {
  isEnabled: boolean
}

export const EmailProviders: FC = () => {
  const [smartEmailing, setSmartEmailing] = useState<IEmailProvider>({
    isEnabled: false
  })
  const { apiCall, response, loading } = useFetch('get')
  const {
    apiCall: disableSmartEmailing,
    loading: disableSmartEmailingLoading
  } = useFetch('post')
  const { control, watch, handleSubmit, reset, setValue } = useForm()

  useEffect(() => {
    SEO({
      title: 'Porta - Email Providers'
    })

    apiCall(`EmailIntegration/GetSmartEmailingStatus`, {}, (response) => {
      reset(response)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSmartEmailing(response)
  }, [response])

  const useMyOwnMailinService = watch('isEnabled')

  const onDisableSmartEmailing = () => {
    disableSmartEmailing(
      'EmailIntegration/UpdateSmartEmailingStatus',
      {
        isEnabled: !useMyOwnMailinService
      },
      () => {
        showToast(
          'success',
          `Smart emailing ${
            useMyOwnMailinService ? 'disabled' : 'enabled'
          } successfully!`
        )
        setSmartEmailing({ isEnabled: !useMyOwnMailinService })
      },
      (error) => {
        showToast('error', error?.response?.data)
        setValue('isEnabled', false)
      }
    )
  }

  return (
    <MainContainer>
      <Card
        cardStyle={CardStyle.bordered}
        cardSpace={CardSpace.xl}
        className="w-72 mr-6"
      >
        <div className="mb-4 justify-between flex items-start">
          <div className="rounded p-1 border border-primary-stroke flex items-center justify-center w-16 h-16">
            <img
              src={require('@/assets/images/SE-logo.png')}
              alt="Unified Logo"
              width={64}
              height={64}
            />
          </div>

          <Controller
            control={control}
            name="isEnabled"
            render={({ field: { onChange, value, ref } }: any) => (
              <ToggleSwitch
                id="is-enabled"
                onChange={(value) => {
                  onChange(value)
                  handleSubmit(onDisableSmartEmailing)()
                }}
                checked={value || false}
                inputRef={ref}
                leftLabel
              />
            )}
          />
        </div>

        <div>
          <Status
            enabled={smartEmailing?.isEnabled}
            className="mb-2"
            loading={loading || disableSmartEmailingLoading}
          />

          <h6 className="text-primary-secText font-bold mb-1 hover:text-primary">
            <NavLink
              to={SMART_EMAILING_PATH}
              state={{
                isEnabled: smartEmailing?.isEnabled
              }}
            >
              SmartEmailing
            </NavLink>
          </h6>

          <Paragraph parahraphSize={ParagraphSize.sm} noMargin>
            Set up your mailing service with SmartEmailing
          </Paragraph>
        </div>
      </Card>
    </MainContainer>
  )
}
