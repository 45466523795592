import { FC } from 'react'
import { Paragraph, ParagraphSize } from '../../../components'

interface IOptionItemContent {
  name: string
  description: string
}

export const EmailVerificationOption: FC<IOptionItemContent> = ({
  name,
  description
}) => {
  return (
    <div className="flex pr-8">
      <div className="p-2 flex items-center justify-center rounded bg-gray-100 w-12 h-12 mr-4 flex-shrink-0 sm:mb-0 mb">
        <img
          src={require('@/assets/images/security-icons/message.svg')}
          alt="Email Icon"
        />
      </div>
      <div>
        <h6 className="text-primary-mainText group-hover:text-primary transition">
          {name}
        </h6>
        <Paragraph noMargin className="mt-1" parahraphSize={ParagraphSize.xs}>
          {description}
        </Paragraph>
      </div>
    </div>
  )
}
