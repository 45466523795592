import { FC, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
//@ts-ignore
import { showToast } from '@nodus/utilities-front'

import {
  Card,
  CardSpace,
  CardStyle,
  Paragraph,
  ParagraphSize,
  SectionTitle,
  Status,
  ToggleSwitch
} from '../components'
import { MainContainer } from '../components/layout/MainContainer'
import { useFetch } from '../hooks'
import { SEO, premiumFeatureEnabled } from '../utils'

export const MultiFactorAuth: FC = () => {
  const { control, watch, reset, handleSubmit } = useForm()
  const { apiCall: getMfaSettings, response, loading } = useFetch('get')
  const { apiCall: updateMfaSettings } = useFetch('post')

  useEffect(() => {
    SEO({
      title: 'Porta - Multi-factor Authentication'
    })

    getMfaSettings(`Users/GetMfaSettings`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    reset(response)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response])

  const watchSms = watch('sms2faEnabled')
  const watchEmail = watch('email2faEnabled')
  const watchAuthenticator = watch('authenticator2faEnabled')

  const authSettings = [
    {
      name: 'SMS',
      description:
        'Users will receive a text message containing a verification code',
      iconUrl: 'chat.svg',
      toggleName: 'sms2faEnabled',
      toggleId: 'sms2faEnabled',
      enabled: watchSms,
      isVisible: premiumFeatureEnabled['sms']
    },
    {
      name: 'Email',
      description:
        'Users will receive an email message containing a verification code',
      iconUrl: 'message.svg',
      toggleName: 'email2faEnabled',
      toggleId: 'email2faEnabled',
      enabled: watchEmail,
      isVisible: premiumFeatureEnabled['email']
    },
    {
      name: 'Authenticator App',
      description:
        'Provide secure Access with a push notification using any authenticator app',
      iconUrl: 'authenticator.svg',
      toggleName: 'authenticator2faEnabled',
      toggleId: 'authenticator2faEnabled',
      enabled: watchAuthenticator,
      isVisible: premiumFeatureEnabled['authApp']
    }
  ]

  const onUpdateMfaSettings = (data: any) => {
    const mfaObj = {
      ...data,
      authenticator2faEnabled: Boolean(data.authenticator2faEnabled),
      email2faEnabled: Boolean(data.email2faEnabled),
      sms2faEnabled: Boolean(data.sms2faEnabled)
    }

    updateMfaSettings(`Users/UpdateMfaSettings`, mfaObj, () => {
      showToast('success', 'Mfa settings saved successfully!')
    })
  }

  return (
    <MainContainer>
      <SectionTitle noMargin>Multi-factor Authentication</SectionTitle>
      <Paragraph className="mb-6" parahraphSize={ParagraphSize.sm}>
        Multi-factor Authentication works by requiring additional factors during
        the login process to prevent unauthorized access.
      </Paragraph>

      <h6 className="text-primary-mainText font-medium">Factors</h6>
      <Paragraph className="mb-6">
        SMS, Email, Authenticator App or a combination of different methods and
        easily enable them across all users and applications.
      </Paragraph>

      <form>
        {authSettings
          ?.filter((item) => item.isVisible === true)
          ?.map((item) => (
            <Card
              cardStyle={CardStyle.bordered}
              cardSpace={CardSpace.none}
              className="mb-4"
              key={item.toggleId}
            >
              <div className="flex flex-wrap justify-between items-center p-4 transition hover:shadow-sm group">
                <div className="flex flex-wrap">
                  <div className="p-2 flex items-center justify-center rounded bg-gray-100 w-12 h-12 mr-4 flex-shrink-0 sm:mb-0 mb">
                    {/* TODO: should be replaced with Icon */}
                    <img
                      src={require(`@/assets/images/security-icons/${item.iconUrl}`)}
                      alt="Security Icon"
                    />
                  </div>
                  <div>
                    <h6 className="text-primary-mainText group-hover:text-primary transition">
                      {item.name}
                    </h6>
                    <Paragraph
                      noMargin
                      className="mt-1"
                      parahraphSize={ParagraphSize.xs}
                    >
                      {item.description}
                    </Paragraph>
                  </div>
                </div>

                <div className="flex justify-center">
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <>
                      <Status enabled={item.enabled} />

                      <Controller
                        control={control}
                        name={item.toggleName}
                        render={({ field: { onChange, value, ref } }) => (
                          <ToggleSwitch
                            wrapperClassName="flex ml-7"
                            id={item.toggleId}
                            onChange={(value) => {
                              onChange(value)
                              handleSubmit(onUpdateMfaSettings)()
                            }}
                            checked={value || false}
                            inputRef={ref}
                            value={value}
                          />
                        )}
                      />
                    </>
                  )}
                </div>
              </div>
            </Card>
          ))}
      </form>
    </MainContainer>
  )
}
