import { FC, LabelHTMLAttributes, ReactNode } from "react";
import { cx } from "../../utils/classnames";

export interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  error?: boolean;
  success?: boolean;
  textColor?: string;
  children: ReactNode;
}

export const Label: FC<LabelProps> = ({
  error = false,
  success = false,
  children = null,
  textColor = "",
  className = "",
  ...props
}) => (
  <label
    {...props}
    className={cx([
      "text-sm inline-block",
      className,
      !error && !success && textColor,
      error && "text-warning",
      success && "text-confirm",
    ])}
  >
    {children && children}
  </label>
);
