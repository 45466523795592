import { useNavigate } from 'react-router-dom'
import { BtnSize, BtnStyle, BtnType, Button } from '../../components/button'
import { cancelBtnClasses, cx, primaryBtnClasses } from '../../utils'

import { Container } from '../layout/Container'

interface IFormWrapper {
  loading?: boolean
  isDisabled?: boolean
  onSubmit: () => void
  children: any
  className?: string
  sticky?: boolean
  prevPage?: string
  submitBtnType?: 'submit' | 'reset' | 'button' | undefined
}

export const FormWrapper = ({
  loading,
  isDisabled,
  onSubmit,
  children,
  className,
  sticky,
  submitBtnType,
  prevPage
}: IFormWrapper) => {
  const navigate = useNavigate()

  const backTo = () => {
    if (prevPage) {
      navigate(prevPage || '/')
    } else {
      navigate(-1)
    }
  }

  return (
    <form className={cx(['flex flex-col h-full relative', className])}>
      <Container
        className="flex-grow h-full flex-col flex"
        childClassName="h-full flex-grow w-full flex-col flex"
      >
        <div className="mb-auto">{children}</div>

        <div
          className={cx([
            'pt-4 sm:pt-6 border-t border-primary-stroke text-right z-10 mt-12',
            sticky && 'sticky bottom-0 bg-white'
          ])}
        >
          <Button
            type="button"
            btnType={BtnType.secondary}
            btnStyle={BtnStyle.neutral}
            btnSize={BtnSize.normal}
            onClick={backTo}
            className={cx([
              cancelBtnClasses,
              'flex-1 sm:flex-none align-middle'
            ])}
          >
            Cancel
          </Button>

          <Button
            type={submitBtnType || 'submit'}
            onClick={onSubmit}
            btnStyle={BtnStyle.primary}
            btnSize={BtnSize.normal}
            className={cx([
              'ml-4 inline-flex items-center align-middle',
              primaryBtnClasses
            ])}
            disabled={isDisabled || loading}
          >
            {loading && (
              <img
                src={require('@/assets/images/oval.svg')}
                alt="Spinner"
                width={16}
                className="mr-2"
              />
            )}
            Save
          </Button>
        </div>
      </Container>
    </form>
  )
}
