import { FC, useEffect } from 'react'

import { useForm } from 'react-hook-form'
//@ts-ignore

import { FormFooter, Loader, PageHeadeline, Paragraph } from '../components'
import { useFetch } from '../hooks'
import { RadioButtonSection } from './components'

export const AuthFlow: FC = () => {
  const { apiCall: enableTwoStepLogin, loading } = useFetch('post')
  const {
    apiCall: getTwoStepLoginStatus,
    loading: getTwoStepLoginStatusLoading,
  } = useFetch('get')
  const { register, handleSubmit, reset, watch } = useForm()

  useEffect(() => {
    getTwoStepLoginStatus(
      'Security/GetAuthenticationSetting?setting=TwoStepLoginEnabled',
      {},
      (data) => {
        reset({ enabled: data })
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const authFlowMethod = watch('enabled')
  const twoStepLoginEnabled = authFlowMethod === 'True' ? true : false

  const onEnablePasswordLessLogin = () => {
    enableTwoStepLogin(
      `Security/SedIdentifierFirstFlowEnabled?enabled=${twoStepLoginEnabled}`,
      {}
    )
  }

  return (
    <form className="flex flex-col h-full w-full">
      <div className="flex h-full">
        <div className="w-1/2 pr-6">
          <PageHeadeline
            title="Authentication Flow"
            description="Configure how your users will authenticate when they login."
            className="mb-6"
          />
          {getTwoStepLoginStatusLoading ? (
            <Loader />
          ) : (
            <div>
              <RadioButtonSection
                checked={!twoStepLoginEnabled}
                register={register('enabled')}
                value="False"
                id="plus-password"
                content={
                  <div className="pr-10">
                    <h6 className="text-base">Identifier + Password</h6>
                    <Paragraph>
                      A single login screen prompts users for their user
                      identifier and their password. This is set as the default
                      experience.
                    </Paragraph>
                  </div>
                }
              />

              <RadioButtonSection
                checked={twoStepLoginEnabled}
                register={register('enabled')}
                value="True"
                id="identifier-first"
                content={
                  <div className="pr-10">
                    <h6 className="text-base">Identifier First</h6>
                    <Paragraph>
                      An initial login screen prompts users for their user
                      identifier, then a different screen prompts users for
                      their password.
                    </Paragraph>
                  </div>
                }
              />
            </div>
          )}
        </div>

        {!getTwoStepLoginStatusLoading && (
          <div className="flex items-center justify-center w-1/2 bg-primary-stroke p-4 relative identifier-first-overlay">
            {!twoStepLoginEnabled && (
              <img
                src={require('@/assets/images/identifier-password.png')}
                alt="Identifier Password"
              />
            )}
            {twoStepLoginEnabled && (
              <img
                src={require('@/assets/images/identifier-first.png')}
                alt="Identifier First"
              />
            )}
          </div>
        )}
      </div>

      {!getTwoStepLoginStatusLoading && (
        <FormFooter
          onSubmit={handleSubmit(onEnablePasswordLessLogin)}
          loading={loading}
          mtAuto
          pb="pb-0"
          className="w-full"
        />
      )}
    </form>
  )
}
