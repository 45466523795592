import { cx } from "../../utils";

interface IDefaultTag {
  className?: string;
}

export const DefaultTag: React.FC<IDefaultTag> = ({ className }) => {
  return (
    <span
      className={cx([
        "px-2 py-0.5 rounded-full border border-primary-stroke text-sm text-primary-secText font-normal",
        className,
      ])}
    >
      Default
    </span>
  );
};
