import { cx } from '../../utils'

interface IStatus {
  className?: string
  enabled: boolean
  loading?: boolean
}

export const Status: React.FC<IStatus> = ({ className, enabled, loading }) => {
  return (
    <div className={cx(['flex items-center text-sm h-5', className])}>
      {loading ? (
        <img
          src={require('@/assets/images/oval.svg')}
          alt="Spinner"
          width={16}
          className="mr-2"
        />
      ) : (
        <>
          <span
            className={cx([
              'w-2 h-2 rounded-full block mr-2',
              enabled ? 'bg-confirm' : 'bg-warning'
            ])}
          ></span>
          {enabled ? 'Enabled' : 'Disabled'}
        </>
      )}
    </div>
  )
}
