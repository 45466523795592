import { ChangeEvent, FC, useEffect, useState } from 'react'
//@ts-ignore
import { GjIconReact as Icon } from '@nodus/utilities-front'
//@ts-ignore
import { showToast } from '@nodus/utilities-front'

import { Accordion, Modal, ModalSize } from '../../../components'
import Search from '../../../components/form/Search'
import { useFetch, useLoadMore, useToggle } from '../../../hooks'
import { cx, inputClasses } from '../../../utils'

interface IBlockedIpsList {
  className?: string
  endpoint: string
  listOpened?: boolean
}

interface IBlockedIpsResponse {
  id: string
  ipAddressBlocked: string
  emailAttemptBlocked: string
  blockType: string
  dateBlocked: string
  dateBlockEnd: number
}

export const BlockedIpsList: FC<IBlockedIpsList> = (props) => {
  const { onLoadMore, itemNum } = useLoadMore(4, 4)
  const { visible, toggle } = useToggle()
  const [selectedApi, setSelectedApi] = useState<IBlockedIpsResponse>()
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState<IBlockedIpsResponse[]>([])

  const { apiCall: getBlockedIPs, response: blockedIps } = useFetch('get')
  const {
    apiCall: deleteBlockedIp,
    response: afterDeleteRes,
    loading: afterDeleteLoading
  } = useFetch('delete')

  useEffect(() => {
    getBlockedIPs(props.endpoint)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const results = blockedIps?.filter((api: IBlockedIpsResponse) =>
      api?.ipAddressBlocked?.toLowerCase().includes(searchTerm)
    )

    setSearchResults(searchTerm !== '' ? results : results?.slice(0, itemNum))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, itemNum, blockedIps])

  const onDeleteBlockeIP = () => {
    deleteBlockedIp(`Users/UnBlockIpAddress?Id=${selectedApi?.id}`, {}, () => {
      showToast('success', 'Blocked ip deleted successfully!')
    })
  }

  useEffect(() => {
    if (!!afterDeleteRes) {
      toggle()
      const filterdIps = searchResults.filter(
        (item) => item.id !== selectedApi?.id
      )
      setSearchResults(filterdIps)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afterDeleteRes])

  return (
    <>
      <Accordion
        title="Blocked IP Addresses List"
        opened={props.listOpened || false}
        content={
          <>
            <div>
              <Search
                placeholder="Search blocked IP .."
                className={cx(['w-full', inputClasses])}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setSearchTerm(event.target.value)
                }
              />

              <ul
                className={cx([
                  'border-primary-stroke mt-2',
                  itemNum < searchResults?.length && 'border-b mb-3'
                ])}
              >
                {searchResults?.length < 1 && (
                  <li className="py-4 text-sm text-primary-mainText">
                    No ip added yet.
                  </li>
                )}

                {searchResults?.map((item: IBlockedIpsResponse) => (
                  <li
                    key={item.id}
                    className={cx([
                      'flex justify-between transition group py-4 text-sm text-primary-mainText border-t border-b border-transparent',
                      'hover:text-primary hover:border-primary-stroke'
                    ])}
                  >
                    {item?.ipAddressBlocked}

                    <button
                      type="button"
                      onClick={() => {
                        toggle()
                        setSelectedApi(item)
                      }}
                      className="text-primary-secText hover:text-warning opacity-0 transition group-hover:opacity-100"
                      title="Delete"
                    >
                      <span className="w-5 h-5 inline-block">
                        <Icon name="Delete" size={22} />
                      </span>
                    </button>
                  </li>
                ))}
              </ul>

              {itemNum < searchResults?.length && searchTerm === '' && (
                <button
                  className="text-xs text-primary hover:underline focus:outline-none mb-3"
                  onClick={onLoadMore}
                  type="button"
                >
                  Load more...
                </button>
              )}
            </div>
          </>
        }
      />

      {visible && (
        <Modal
          hide={toggle}
          visible={visible}
          title="Delete Blocked IP"
          onConfirmClick={onDeleteBlockeIP}
          confirmBtnText="Delete"
          modalSize={ModalSize.sm}
          warningModal
          withFooter
          loading={afterDeleteLoading}
          blockOutsideClick
        >
          <p className="text-sm text-primary-mainText mb-4">
            Are you sure you want to delete{' '}
            <strong className="font-medium">
              {selectedApi?.ipAddressBlocked}
            </strong>
            ?
          </p>
        </Modal>
      )}
    </>
  )
}
