import { RouteProps } from 'react-router-dom'
import {
  ATTACK_PROTECTION_PATH,
  AUTHENTICATION_FLOW,
  AUTHENTICATION_PASSLESS,
  AUTHENTICATION_SETTINGS_PATH,
  BOT_DETECTION_PATH,
  BREACHED_PASS_PATH,
  BRUTE_FORCE_PATH,
  INTEGRATIONS_PATH,
  IP_THROTTLING_PATH,
  MULTI_FACTOR_AUTH_PATH,
  SETTINGS_GENERAL_PATH,
  SETTINGS_LANGUAGES_PATH,
  SMART_EMAILING_PATH
} from '../constants'
import {
  AttackProtection,
  AuthFlow,
  AuthSettings,
  BotDetection,
  BreachPassDetection,
  BruteForceProtection,
  EditLanguageKeys,
  EmailProviders,
  GeneralSettings,
  Languages,
  MultiFactorAuth,
  PasswordLess,
  SmartEmailing,
  SuspiciousIpThrottling
} from '../pages'
import { premiumFeatureEnabled } from '../utils'

type BreadcrumbsPropsItem = {
  name?: string
  to?: string
}

enum Permission {
  admin = 'SuperAdmin',
  usersEditor = 'EditorUsers',
  appsEditor = 'EditorSpecificApps'
}

const { admin, appsEditor } = Permission

export interface RoutesProps extends RouteProps {
  name?: string
  crumbs?: BreadcrumbsPropsItem[]
  permissions?: Permission[]
}

export const applicationsRoutes: RoutesProps[] = [
  {
    path: SETTINGS_GENERAL_PATH,
    element: <GeneralSettings />,
    name: 'General Settings',
    crumbs: [{ name: 'Settings' }, { name: 'General Settings' }],
    permissions: [admin]
  },
  {
    path: SETTINGS_LANGUAGES_PATH,
    element: <Languages />,
    name: 'Languages',
    crumbs: [{ name: 'Settings' }, { name: 'Languages' }],
    permissions: [admin]
  },
  {
    path: `${SETTINGS_LANGUAGES_PATH}/:key`,
    element: <EditLanguageKeys />,
    name: 'Languages',
    crumbs: [
      { name: 'Settings' },
      { name: 'Languages', to: SETTINGS_LANGUAGES_PATH },
      { name: 'Edit keys' }
    ],
    permissions: [admin]
  },
  {
    ...(premiumFeatureEnabled['authFlow'] === true && {
      path: AUTHENTICATION_FLOW,
      element: <AuthFlow />,
      name: 'Authentication',
      crumbs: [{ name: 'Authentication' }, { name: 'Authetication Flow' }],
      permissions: [admin]
    })
  },
  {
    path: AUTHENTICATION_SETTINGS_PATH,
    element: <AuthSettings />,
    name: 'Authentication',
    crumbs: [{ name: 'Authentication' }, { name: 'Authentication Settings' }],
    permissions: [admin]
  },
  {
    ...(premiumFeatureEnabled['passwordLess'] === true && {
      path: AUTHENTICATION_PASSLESS,
      element: <PasswordLess />,
      name: 'Authentication',
      crumbs: [{ name: 'Authentication' }, { name: 'Passwordless' }],
      permissions: [admin]
    })
  },
  // Intergrations - Email Providers
  {
    path: INTEGRATIONS_PATH,
    element: <EmailProviders />,
    name: 'Email Providers',
    crumbs: [{ name: 'Integrations' }, { name: 'Email Providers' }],
    permissions: [admin]
  },
  {
    path: SMART_EMAILING_PATH,
    element: <SmartEmailing />,
    name: 'Configure Smart Emailing',
    crumbs: [
      { name: 'Integrations' },
      { name: 'Email Providers', to: INTEGRATIONS_PATH },
      { name: 'Configure Smart Emailing' }
    ],
    permissions: [admin]
  },
  // Security
  {
    path: ATTACK_PROTECTION_PATH,
    element: <AttackProtection />,
    name: 'Security',
    crumbs: [{ name: 'Security' }, { name: 'Attack Protection' }],
    permissions: [admin]
  },
  {
    path: MULTI_FACTOR_AUTH_PATH,
    element: <MultiFactorAuth />,
    name: 'Security',
    crumbs: [{ name: 'Security' }, { name: 'Multi-factor Auth' }],
    permissions: [admin]
  },

  {
    path: ATTACK_PROTECTION_PATH + BOT_DETECTION_PATH,
    element: <BotDetection />,
    name: 'Security',
    crumbs: [
      { name: 'Security' },
      { name: 'Attack Protection', to: ATTACK_PROTECTION_PATH },
      { name: 'Bot Detection' }
    ],
    permissions: [admin]
  },

  {
    path: ATTACK_PROTECTION_PATH + IP_THROTTLING_PATH,
    element: <SuspiciousIpThrottling />,
    name: 'Security',
    crumbs: [
      { name: 'Security' },
      { name: 'Attack Protection', to: ATTACK_PROTECTION_PATH },
      { name: 'Suspicius IP Throttling' }
    ],
    permissions: [admin]
  },

  {
    path: ATTACK_PROTECTION_PATH + BRUTE_FORCE_PATH,
    element: <BruteForceProtection />,
    name: '',
    crumbs: [
      { name: 'Security' },
      { name: 'Attack Protection', to: ATTACK_PROTECTION_PATH },
      { name: 'Brute force protection' }
    ],
    permissions: [admin]
  },

  {
    path: ATTACK_PROTECTION_PATH + BREACHED_PASS_PATH,
    element: <BreachPassDetection />,
    name: '',
    crumbs: [
      { name: 'Security' },
      { name: 'Attack Protection', to: ATTACK_PROTECTION_PATH },
      { name: 'Breached password detection' }
    ],
    permissions: [admin]
  }
]
