export const USERS_PATH = '/users'

const BASE_PATH = '/porta/gjirafa'

const SECURITY_PATH = `${BASE_PATH}/security`
export const ATTACK_PROTECTION_PATH = `${SECURITY_PATH}/attack-protection`
export const MULTI_FACTOR_AUTH_PATH = `${SECURITY_PATH}/multi-factor-auth`
export const BOT_DETECTION_PATH = '/bot-detection'
export const IP_THROTTLING_PATH = '/suspicious-ip-throttling'
export const BRUTE_FORCE_PATH = '/brute-force-protection'
export const BREACHED_PASS_PATH = '/breached-password-detection'

export const SETTINGS_PATH = `${BASE_PATH}/settings`
export const SETTINGS_GENERAL_PATH = `${SETTINGS_PATH}/general-settings`
export const SETTINGS_LANGUAGES_PATH = `${SETTINGS_PATH}/languages`

export const AUTHENTICATION = `${BASE_PATH}/authentication`
export const AUTHENTICATION_FLOW = `${AUTHENTICATION}/flow`
export const AUTHENTICATION_PASSLESS = `${AUTHENTICATION}/password-less`
export const AUTHENTICATION_SETTINGS_PATH = `${AUTHENTICATION}/settings`

export const INTEGRATIONS_PATH = `${BASE_PATH}/integrations/email-providers`
export const SMART_EMAILING_PATH = `${INTEGRATIONS_PATH}/smart-emailing`
