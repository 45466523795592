import { ReactNode } from 'react'
import { cx } from '../../../utils'

export enum ContainerSize {
  md = 'w-1/2',
  sm = 'w-1/3'
}

interface IWrapper {
  children: ReactNode
  title?: string
  className?: string
  containerSize?: ContainerSize
  noMarginTop?: boolean
}

export const Wrapper: React.FC<IWrapper> = ({
  children,
  title,
  className = '',
  containerSize = ContainerSize.md,
  noMarginTop
}) => {
  return (
    <div
      className={cx([
        'mx-auto flex flex-col h-full',
        containerSize,
        className,
        !noMarginTop && 'mt-12'
      ])}
    >
      {Boolean(title) && (
        <h6 className="mb-6 text-primary-mainText">{title}</h6>
      )}

      {children}
    </div>
  )
}
