//@ts-ignore
import { GjIconReact as Icon } from '@nodus/utilities-front';
import { Dropdown, DropdownOption } from "../../components";
import { ILanguage, ILngState } from "../../interfaces";

interface ISelectedLangHeader {
  languages: ILanguage[];
  lState: ILngState;
  setLState: any;
  onLanguageChange: (lang: ILanguage) => void;
}

export const SelectedLangHeader: React.FC<ISelectedLangHeader> = ({
  languages,
  lState,
  onLanguageChange,
  setLState,
}) => {
  return (
    <div className="border-b border-primary-stroke flex justify-between pb-4 mb-6">
      <div className="flex items-center">
        <Dropdown
          left
          width="w-36"
          noPadding
          dropdownContent={
            <>
              {languages?.map((lang, index) => (
                <DropdownOption
                  key={index}
                  label={lang.name}
                  onClick={() => {
                    onLanguageChange(lang);
                    // Should change here

                    // setLState({
                    //   ...lState,
                    //   language: lang,
                    // });
                    // document.body.click();
                  }}
                />
              ))}
            </>
          }
        >
          <button type="button" className="flex items-center">
            <img
              src={`https://flagcdn.com/48x36/${lState.language?.countryCode?.toLocaleLowerCase()}.png`}
              width={18}
              alt=""
              className="mr-2"
            />
            <span className="text-sm text-primary-secText">
              {lState?.language?.name}
            </span>
            <span className="text-primary-secText ml-2 inline-block w-4 h-4">
              <Icon name="ArrowDown" size={18} />
            </span>
          </button>
        </Dropdown>
      </div>
    </div>
  );
};
