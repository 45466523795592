import { ChangeEventHandler, MouseEventHandler } from 'react'
//@ts-ignore
import { GjIconReact as Icon } from '@nodus/utilities-front'

import { Textarea } from '../../components'
import { INameSpace } from '../../interfaces'
import { cx, textareaClasses } from '../../utils'

interface IselectedLngResource {
  namespace: INameSpace
  onMouseEnter: MouseEventHandler<HTMLDivElement>
  onMouseLeave: MouseEventHandler<HTMLDivElement>
  onInputChange: ChangeEventHandler<HTMLTextAreaElement>
  onButtonClick: MouseEventHandler<HTMLButtonElement>
  loading: boolean
  currentId: string
}

export const SelectedLngResource: React.FC<IselectedLngResource> = ({
  namespace,
  onMouseEnter,
  onMouseLeave,
  onInputChange,
  onButtonClick,
  loading,
  currentId
}) => {
  return (
    <div
      className="flex flex-1 items-start mb-3"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Textarea
        value={namespace.value}
        containerClassName="flex-1"
        className={cx([textareaClasses, 'h-10 language-keys-textarea'])}
        placeholder={namespace?.previousValue}
        onChange={onInputChange}
      />

      <button
        type="button"
        onClick={onButtonClick}
        disabled={loading || !namespace.touched}
        className={cx([
          'w-10 h-10 flex items-center justify-center border rounded text-primary-secText ml-2 transition relative',
          'disabled:cursor-not-allowed',
          !namespace.touched && 'border-primary-stroke hover:bg-primary-bg',
          namespace.touched &&
            'dirty bg-primary border-primary text-white hover:bg-primary-hover hover:text-white active:bg-primary-pressed',
          loading && currentId === namespace.id && 'opacity-50'
        ])}
      >
        {loading && currentId === namespace.id ? (
          <img
            src={require('@/assets/images/oval.svg')}
            alt="Spinner"
            width={16}
            className="mr-2"
          />
        ) : (
          <div>
            <Icon size={18} name="Check" />
          </div>
        )}
      </button>
    </div>
  )
}
